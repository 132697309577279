import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = [ "text","button"]
  connect() {
  }

  copy() {
    const textToCopy = this.textTarget.innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      this.buttonTarget.classList.add('animate-ping');
      // Remove the class after 500ms
      setTimeout(() => {
        this.buttonTarget.classList.remove('animate-ping');
      }, 1000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
    return false;
  }
}
