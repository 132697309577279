import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="3d-album"
export default class extends Controller {
  static values = {
    front: String,
    back: String,
    vinyl: String,
    color: String
  }
  connect() {
    // Convert the string to a hexadecimal format for the color
    const colorHex = parseInt(this.colorValue, 16); // Convert the string "000000" to a number 0x000000
    const targetHeight = 400;

    // Initialize scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(35, 400 / 400, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.element.appendChild(renderer.domElement);

    // Load front and back album textures
    const frontTexture = new THREE.TextureLoader().load(this.frontValue);
    const backTexture = new THREE.TextureLoader().load(this.backValue);

    // Create a group to hold the album sleeve and vinyl record
    const albumGroup = new THREE.Group();

    // Create the album sleeve (a thin cuboid)
    const geometry = new THREE.BoxGeometry(5, 5, 0.05); // Thin rectangle representing the sleeve
    const materialFront = new THREE.MeshBasicMaterial({ map: frontTexture });
    const materialBack = new THREE.MeshBasicMaterial({ map: backTexture });
    const materialSides = new THREE.MeshBasicMaterial({ color: 0xFFFFFF }); // Black for the sides of the sleeve

    // Create the album cover mesh with front and back textures
    const albumSleeve = new THREE.Mesh(
      geometry,
      [materialSides, materialSides, materialSides, materialSides, materialFront, materialBack] // Apply front and back textures
    );
    albumSleeve.position.set(-0.75, 0, 0);

    // Add the album sleeve to the scene
    albumGroup.add(albumSleeve);

    const recordTexture = new THREE.TextureLoader().load(this.vinylValue);

    // Create the vinyl record (a thin disc)
    const vinylGeometry = new THREE.CylinderGeometry(2.4, 2.4, 0.03, 32); // A disc with radius 2.4 and thin height
    const vinylSides = new THREE.MeshBasicMaterial({ color: colorHex }); // Black for the vinyl record
    const vinylTop = new THREE.MeshBasicMaterial({ map: recordTexture }); // Texture for the top (front) of the record
    const vinylBottom = new THREE.MeshBasicMaterial({ map: recordTexture }); // Texture for the bottom (back) of the record

    const vinylRecord = new THREE.Mesh(vinylGeometry, [vinylSides, vinylTop, vinylBottom]);

    // Position the vinyl record slightly sticking out from the top of the sleeve

    vinylRecord.position.set(0.75, 0, 0); // Adjust Y to partially stick out (half the height of sleeve)
    vinylRecord.rotation.x = Math.PI / 2; // Rotate so it's flat, parallel to the album sleeve

    // Optional: Slightly rotate the record to make it more dynamic
    // vinylRecord.rotation.z = Math.PI / 10; // Slight tilt on the z-axis

    // Add the vinyl record to the scene
    albumGroup.add(vinylRecord);
    albumGroup.position.set(0, 0, 0);

    // Add the album group to the scene
    scene.add(albumGroup);

    // Position the camera slightly away from the album
    camera.position.z = 10;

    // Mouse tracking variables
    let mouseX = 0;
    let mouseY = 0;
    let isInteracting = false;

    // Raycaster and mouse position
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    let targetRotationX = -0.4; // Target rotation for the default position (X-axis)
    let targetRotationY = -0.2; // Target rotation for the default position (Y-axis)
    let rockDirection = 1; // Controls rocking direction

    // Function to reset the album to its default state with a rocking motion
    function resetAlbumPosition() {
      // Slowly rock back and forth around the Y-axis
      const rockingSpeed = 0.005; // Speed of rocking
      const rockingLimit = 0.5; // Maximum rock angle

      if (!isInteracting) {
        albumGroup.rotation.x += (targetRotationX - albumGroup.rotation.x) * 0.05;
        albumGroup.rotation.y += (targetRotationY - albumGroup.rotation.y) * 0.05;

        // // Apply rocking effect to Y-axis when idle
        // albumSleeve.rotation.y += rockDirection * rockingSpeed;
        // // console.log(albumSleeve.rotation.y,rockingLimit,albumSleeve.rotation.y)
        // if (albumSleeve.rotation.y > rockingLimit || albumSleeve.rotation.y < -rockingLimit) {
        //   rockDirection *= -1; // Change rocking direction when limit is reached
        // }
      }
    }

    // Capture mouse movements within the 500x500 square
    this.element.addEventListener('mousemove', (event) => {
      const containerRect = renderer.domElement.getBoundingClientRect();

      // Normalize mouse coordinates to [-1, 1] within the container bounds
      mouseX = ((event.clientX - containerRect.left) / containerRect.width) * 2 - 1;
      mouseY = -((event.clientY - containerRect.top) / containerRect.height) * 2 + 1;

      // When mouse is moving, user is interacting
      isInteracting = true;

      // Map mouse movement to rotation
      albumGroup.rotation.x = mouseY * 1.0; // Rotate less for a smoother effect
      albumGroup.rotation.y = mouseX * 3.0;
    });

    // Detect when the mouse leaves the container to stop interaction
    this.element.addEventListener('mouseleave', () => {
      isInteracting = false; // Mouse left the square, stop interaction
    });

    // Animation loop
    function animate() {
      requestAnimationFrame(animate);

      // Only rotate the album sleeve based on mouse movement when interacting
      if (isInteracting) {
        // Immediate response to mouse movement handled above in 'mousemove'
      } else {
        // Return to default position with slow rocking when not interacting
        resetAlbumPosition();
      }

      renderer.render(scene, camera);
    }

    const containerRect = renderer.domElement.parentElement.getBoundingClientRect();
    renderer.setSize(containerRect.width, targetHeight);
    camera.aspect = containerRect.width / targetHeight;
    camera.updateProjectionMatrix();

    animate();

    // Adjust canvas size when window is resized (not needed here, but added for completeness)
    window.addEventListener('resize', () => {
      const containerRect = renderer.domElement.parentElement.getBoundingClientRect();
      renderer.setSize(containerRect.width, targetHeight);
      camera.aspect = containerRect.width / targetHeight;
      camera.updateProjectionMatrix();
    });
  }
}
