import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit"
export default class extends Controller {
  static targets = [ "form", "button"]
  connect() {
    this.debounceTimeout = null;
  }

  inputChanged(event) {
    const targetType = event.target.type;
    
    // Change the button text to "Save" when the user makes any changes
    this.updateButtonText("Save");  
    
    // For text inputs, debounce the form submission to avoid triggering after every keystroke
    if (targetType === "text" || targetType === "textarea") {
      this.debounce(() => this.submitForm(), 500); // 500ms delay
    } else {
      this.submitForm(); // Immediately submit for other input types
    }
  }

  submitForm() {
    // Submit the form
    this.formTarget.requestSubmit();
    // After form submission, change the button text to "Saved"
    this.updateButtonText("Saved");

    // Optionally, you could hide the "Saved" message after a few seconds
    setTimeout(() => this.updateButtonText("Save"), 3000);
  }

  debounce(callback, delay) {
    clearTimeout(this.debounceTimeout); // Clear any previous timeout
    this.debounceTimeout = setTimeout(callback, delay); // Set a new timeout
  }


  updateButtonText(text) {
    this.buttonTarget.value = text;
  }
}
