import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "textarea" ]
  connect() {
    this.adjustHeight();
  }

  adjustHeight() {
    const textarea = this.textareaTarget;
    // Reset height so we can shrink it as well as expand
    textarea.style.height = 'auto';
    // Set the height to scrollHeight to fit the content
    textarea.style.height = textarea.scrollHeight + 10 + 'px';
  }
}
