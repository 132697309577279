import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stripe-checkout"
export default class extends Controller {

  static values = {
    sessionPath: String,
    apiKey: String
  }
  connect() {
    // This runs when the controller is connected to the DOM
    this.stripe = Stripe(this.apiKeyValue);
    this.initializeCheckout();
  }

  async initializeCheckout() {
    // Fetch the client secret from the server
    const fetchClientSecret = async () => {
      const response = await fetch(this.sessionPathValue, {
        method: "POST",
      });
      const { clientSecret } = await response.json();
      return clientSecret;
    };

    // Initialize Stripe's embedded checkout
    const checkout = await this.stripe.initEmbeddedCheckout({
      fetchClientSecret,
    });

    // Mount Checkout UI to the target element
    checkout.mount(this.element);
  }
}
